import React from "react";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
// import { aboutPic1 } from "../graphql";
import { graphql } from "gatsby";

export const query = graphql`
  query MyQuery {
    first: file(relativePath: { eq: "Carousel/BBIS-1.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;

export default ({ data }) => (
  <Layout>
    <SEO
      title="About "
      description="BBIS Heating has been established in domestic and commercial clients for over forty years, working on commercial boiler service &amp; domestic heating maintenance."
      keywords="BBIS, about us, commercial heating"
    />
    <div class="text-center">

          <Img
            fluid={data.first.childImageSharp.fluid}
            className="rounded mx-auto d-block img-fluid pt-2"
          />

    </div>
    <div class="white-segment text-left">
      <h1 class="heading-color">About us</h1>
      <p class="">
        BBIS - boilers, burners and incinerator services, was founded in 1973.
        The business mainly serviced and maintained old gas-powered
        incinerators, for the health and pharmaceutical sector, but also had
        contracts with the Royal mint, and aerospace companies. The company
        continued to flourish and gain new contacts including schools, hospitals
        and hotels. Charlie Beech became the new managing director in 2006 and
        began securing new contracts in property maintenance. The “commercial
        heating’ “element was integrated into the businesses name, as Charlie
        felt he wanted to push forward into the commercial sector.{" "}
      </p>
      <p class="">
        BBIS commercial heating Ltd continues to go from strength to strength,
        thanks to our excellent team of engineers and our loyal customers. We
        are always striving to give the very best possible customer service,
        being affordable, efficient and of great quality. Charlies drive and
        passion for his business has seen BBIS grow into an established company
        with a large portfolio to be proud of.
      </p>
      <p class="">
        Please call BBIS Commercial heating today, for the best advice in
        achieving cost-effective and energy- efficient heating solutions for
        your home or commercial property.{" "}
      </p>
    </div>
  </Layout>
);
